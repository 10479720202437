import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { IsAuth, useAuth } from '@/context/AuthContext.js';
import { useTranslation } from 'react-i18next';
import FieldWithTogglePassword from '@/components/FieldWithTogglePassword';
import useLocalStorage from '@/hooks/useLocalStorage';
import { getUserSetting } from '@/services/UserSettingsService';
import { languageKey } from '@/variables/Urls';
import OnBoarding from '@/images/cover/onboarding.svg';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('signIn.formErrors.email.invalid')
    .required('signIn.formErrors.email.required'),
  password: Yup.string().required('signIn.formErrors.password.required'),
});
const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { Login } = useAuth();
  const isLogged = IsAuth();
  const { t } = useTranslation();
  const [colorMode, setColorMode] = useLocalStorage('color-theme', 'light');
  let { user, loading } = useAuth();

  const handleSubmit = async (values: any) => {
    const data = await Login({
      email: values.email,
      password: values.password,
    });
  };

  useEffect(() => {
    if (isLogged) {
      navigate('/');
    }
  }, [isLogged]);

  const CustomErrorMessage = (error: string) => {
    return <div className="pb-2 text-red-500">{t(error)}</div>;
  };

  return (
    <>
      <div className="h-screen rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-wrap items-center">
          <div className="hidden w-full xl:block xl:w-1/2">
            <div className="px-26 py-17.5 text-center">
              <Link className="mb-5.5 inline-block" to="/">
                {/* <img className="hidden dark:block" src={Logo} alt="Logo" />
                <img className="dark:hidden" src={LogoDark} alt="Logo" /> */}
              </Link>

              {/* <p className="2xl:px-20">{t('signIn.welcomeMessage')}</p> */}
              {/* <p className="2xl:px-20">ALLINONE</p> */}

              <span className="mt-15 inline-block">
                <OnBoarding className="h-80 w-100 " />
              </span>
            </div>
          </div>

          <div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
            <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
              <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                {t('signIn.title', { name: 'ORIVIS' })}
              </h2>

              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="mb-4">
                    <label
                      className="mb-2.5 block font-medium text-black dark:text-white"
                      htmlFor="email"
                    >
                      {t('signIn.emailLabel')}
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                    <ErrorMessage
                      name="email"
                      render={(error: string) => CustomErrorMessage(error)}
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      className="mb-2.5 block font-medium text-black dark:text-white"
                      htmlFor="password"
                    >
                      {t('signIn.passwordLabel')}
                    </label>
                    <Field
                      id="password"
                      name="password"
                      placeholder="6+ Characters, 1 Capital letter"
                      component={FieldWithTogglePassword}
                    />

                    <ErrorMessage
                      name="password"
                      component="div"
                      className=" text-red-500"
                      render={(error: string) => CustomErrorMessage(error)}
                    />
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <NavLink
                      className=" text-primary-700"
                      to="/forget-password"
                    >
                      Forget your password ?
                    </NavLink>
                    <button
                      type="submit"
                      className="w-50 rounded-lg bg-primary
                    px-6 py-3 font-medium text-white"
                    >
                      {t('signIn.signInButton')}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
